import { Link } from 'gatsby'
import React from 'react'
import MvTextPage from '../components/common/MvTextPage'
import MvTextPageHighlight from '../components/common/MvTextPageHighlight'
import MvTextPageSection from '../components/common/MvTextPageSection'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'

export default ({ data }) => (
  <Layout>
    <MvTextPage title="Presse Material">
      <MvTextPageSection>
        <p>
          Zusätzlich zu den Informationen über{' '}
          <Link to="/verein">unseren Verein</Link> finden Sie auf dieser Seite
          weitere Presse Materialien zum Herunterladen.
        </p>
      </MvTextPageSection>

      <MvTextPageHighlight
        title="Presse Kit"
        subtitle="Presse Materialien zum Herunterladen"
        link={{
          href: data.presseKit.publicURL,
          text: 'Presse Kit herunterladen',
        }}
      >
        Das Presse Kit enthält ein Gruppenfoto der Trachtenkapelle sowie das
        Vereinslogo in verschiedenen Ausführungen. Diese Grafiken können gerne
        zum Bewerben unserer Veranstaltungen und Auftritte, sowie für die
        Berichterstattung über unseren Verein verwendet werden.
      </MvTextPageHighlight>
    </MvTextPage>
  </Layout>
)

export const query = graphql`
  query {
    presseKit: file(relativePath: { eq: "download/PresseKit.zip" }) {
      publicURL
    }
  }
`
